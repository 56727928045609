export default [
  {
    _name: "CSidebarNav",
    _children: [
      {
        _name: "CSidebarNavItem",
        name: "لوحة التحكم",
        to: "/dashboard",
        icon: "cil-speedometer",
        badge: {}
      },
      {
        _name: "CSidebarNavItem",
        name: "الطلبات",
        to: "/orders",
        icon: "cil-pencil"
      },
      {
        _name: "CSidebarNavItem",
        name: ` العملاء `,
        to: "/clients",
        icon: "cil-user"
      },
      {
        _name: "CSidebarNavItem",
        name: "الموردين",
        to: "/suppliers",
        icon: "cil-pencil"
      },
      {
        _name: "CSidebarNavItem",
        name: "الفواتير",
        to: "/invoices",
        icon: "cil-pencil"
      },
      {
        _name: "CSidebarNavItem",
        name: "المخزن",
        to: "/stock",
        icon: "cil-pencil"
      },
      {
        _name: "CSidebarNavItem",
        name: "المنتجات",
        to: "/products",
        icon: "cil-pencil"
      },
      {
        _name: "CSidebarNavItem",
        name: "الاحجام",
        to: "/sizes",
        icon: "cil-pencil"
      },
      {
        _name: "CSidebarNavItem",
        name: "الالوان",
        to: "/colors",
        icon: "cil-pencil"
      },
      {
        _name: "CSidebarNavItem",
        name: "الماركات",
        to: "/brands",
        icon: "cil-pencil"
      },
      {
        _name: "CSidebarNavDropdown",
        name: "الفئات",
        icon: "cil-list",
        items: [
          {
            _name: "CSidebarNavItem",
            name: `الفئات الأساسية `, //(${categories})
            to: "/categories",
            icon: "cil-calculator",
            // _badge: {},
            // badge: {text: localStorage.categories,
            // color: "warning",
            shape: "pill",
            id: "categories_badge"
          },
          {
            _name: "CSidebarNavItem",
            name: `الفئات الفرعية `, //(${sub_categories})
            to: "/sub-categories",
            icon: "cil-chart-pie"
          }
        ]
      },

      {
        _name: "CSidebarNavItem",
        name: "الإعلانات",
        to: "/ads",
        icon: "cil-pencil"
      }
    ]
  }
];
